@use "../variables.scss";

nav {
  display: flex;
  background-color: variables.$black-background;
  height: 4rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  .hamburger {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: self-end;
      width: 100%;
      height: 2rem;
      padding: 0 1rem;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      .burger {
        width: 2rem;
        height: 0.25rem;
        background-color: variables.$gray-background;
        border-radius: 10px;
        transition: all 0.3s linear;
      }
    }
  }
  .navbar-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 1240px;
    @media (max-width: 768px) {
      flex-direction: column;
      position: absolute;
      top: 4rem;
      left: 0;
      width: 100%;
      background-color: variables.$black-background;
      transition: max-height 0.3s ease-in-out;
      overflow: hidden;
      max-height: 0;

      &.open {
        max-height: 300px; 
        z-index: 1000;
        padding-bottom: 4rem;
      }
    }
  }
  a {
    color: white;
    text-decoration: none;
    opacity: 0.7;
    &.active {
      opacity: 1;
    }
    &.contato {
      opacity: 0.7;
    }
    @media (max-width: 768px) {
      padding: 1rem 0;
      text-align: center;
    }
  }
}
