@use "../mixins.scss";
@use "../variables.scss";

#admin-agenda {
  display: flex;
  flex-direction: column;
  align-items: center;
  .admin-agenda {
    @include mixins.max-width();
    display: flex;
    flex-direction: column;
    .form-agenda-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 3rem;
      label {
        display: flex;
        flex-direction: column;
        font-size: 0.9rem;
        gap: 0.5rem;
        color: variables.$gray-text;
      }
      input,
      textarea {
        padding: 0.4rem;
      }
      #event-date-time {
        width: 10rem;
      }
      .add-event-btn {
        padding: 0.5rem;
        font-size: 1rem;
        background-color: #28a745;
        color: white;
        font-weight: 600;
        border: none;
        border-radius: 1rem;
        cursor: pointer;
        &:disabled {
          background-color: variables.$gray-text;
          cursor: unset;
        }
      }
    }
    .events-list {
      display: flex;
      flex-direction: column;
      .list-description {
        font-size: 0.9rem;
        span {
          font-weight: 600;
          color: red;
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0;
        li {
          display: flex;
          padding: 0.5rem;
          justify-content: space-between;
          background-color: variables.$gray-background;
          @media (max-width: 480px) {
            flex-direction: column;
          }
          .base-event-name {
            display: flex;
            gap: 1rem;
            color: variables.$black-text;
            font-weight: 600;
            span {
              font-weight: 300;
            }
            @media (max-width: 480px) {
              justify-content: space-between;
            }
          }
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 0.2rem;
            margin: 0 0.5rem 0 1rem;
            background-color: pink;
            cursor: pointer;
            .trash-icon {
              color: red;
              padding: 0.3rem;
              @media (max-width: 480px) {
                margin: 0.5rem;
              }
            }
            @media (max-width: 480px) {
              width: 40px;
              align-self: flex-end;
              margin: 0.5rem 0 0 1rem;
            }
          }
        }
      }
    }
  }
}
