@use "./mixins.scss";
@use "./variables.scss";

@use "./components/navbar";
@use "./components/footer";
@use "./components/hero";
@use "./components/albuns";
@use "./components/streaming";
@use "./components/lyrics";
@use "./components/bio";
@use "./components/heroHome";
@use "./components/videos";
@use "./components/photos";
@use "./components/modal";
@use "./components/events";
@use "./components/login";
@use "./components/adminHeader";
@use "./components/adminAgenda";

html,
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

main {
  margin: 0 auto;
  padding-bottom: 3.5rem;
  color: variables.$black-text();
}

.content {
  opacity: 0; 
  transition: opacity 0.5s ease; 
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
  @media (max-width: 480px) {
    gap: 2rem;
  }
}
.content.loaded {
  opacity: 1; 
}

