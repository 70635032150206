@use "../mixins.scss";

#photos {
  @include mixins.max-width();
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  .photo-gallery {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(9, 5vw);
    grid-gap: 0.5rem;
    @media (max-width: 768px) {
      grid-template-columns: repeat(10, 1fr);
      grid-template-rows: repeat(10, 5vw);
      grid-gap: 0.2rem;
    }
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 0.3rem;
    }
    .photo-1 {
      grid-column-start: 5;
      grid-column-end: 10;
      grid-row-start: 1;
      grid-row-end: 6;
      @media (max-width: 768px) {
        grid-column-start: 1;
        grid-column-end: 6;
        grid-row-start: 1;
        grid-row-end: 6;
      }
    }
    .photo-2 {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 10;
      object-position: center;
      @media (max-width: 768px) {
        grid-column-start: 6;
        grid-column-end: 11;
        grid-row-start: 1;
        grid-row-end: 6;
        object-position: top;
      }
    }
    .photo-3 {
      grid-column-start: 8;
      grid-column-end: 10;
      grid-row-start: 6;
      grid-row-end: 10;
      object-position: center;
      @media (max-width: 768px) {
        grid-column-start: 6;
        grid-column-end: 11;
        grid-row-start: 6;
        grid-row-end: 11;
      }
    }
    .photo-4 {
      grid-column-start: 5;
      grid-column-end: 8;
      grid-row-start: 6;
      grid-row-end: 10;
      object-position: right;
      @media (max-width: 768px) {
        grid-column-start: 1;
        grid-column-end: 6;
        grid-row-start: 6;
        grid-row-end: 11;
      }
    }
  }
}
