$black-background: #260126;
$gray-background: #f8f8f8;
$gray-text: #260126;
$light-text: #dfdfdf;
$black-text: #222222;
$violet-title: #483448;
$underline-btn: #DEB72A;

p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: $gray-text;
}

h1 {
  margin: 2.4rem 0 0 0;
}

h2 {
  margin: 1rem 0;
  font-size: 1.7rem;
  color: $violet-title;
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
}

h3 {
  margin: 0 0 1rem 0;
  font-size: 1.375rem;
  color: $violet-title;
  @media (max-width: 480px) {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }
}

h4 {
  margin: 0;
  color: $gray-text;
}
