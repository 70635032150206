p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: #260126;
}

h1 {
  margin: 2.4rem 0 0 0;
}

h2 {
  margin: 1rem 0;
  font-size: 1.7rem;
  color: #483448;
}
@media (max-width: 768px) {
  h2 {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  h2 {
    font-size: 1.5rem;
  }
}

h3 {
  margin: 0 0 1rem 0;
  font-size: 1.375rem;
  color: #483448;
}
@media (max-width: 480px) {
  h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
}

h4 {
  margin: 0;
  color: #260126;
}

nav {
  display: flex;
  background-color: #260126;
  height: 4rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
nav .hamburger {
  display: none;
}
@media (max-width: 768px) {
  nav .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: self-end;
    width: 100%;
    height: 2rem;
    padding: 0 1rem;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  nav .hamburger .burger {
    width: 2rem;
    height: 0.25rem;
    background-color: #f8f8f8;
    border-radius: 10px;
    transition: all 0.3s linear;
  }
}
nav .navbar-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1240px;
}
@media (max-width: 768px) {
  nav .navbar-container {
    flex-direction: column;
    position: absolute;
    top: 4rem;
    left: 0;
    width: 100%;
    background-color: #260126;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    max-height: 0;
  }
  nav .navbar-container.open {
    max-height: 300px;
    z-index: 1000;
    padding-bottom: 4rem;
  }
}
nav a {
  color: white;
  text-decoration: none;
  opacity: 0.7;
}
nav a.active {
  opacity: 1;
}
nav a.contato {
  opacity: 0.7;
}
@media (max-width: 768px) {
  nav a {
    padding: 1rem 0;
    text-align: center;
  }
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}
footer .footer-content {
  display: flex;
  justify-content: space-between;
  width: 90vw;
  max-width: 1240px;
  margin: 2.5rem auto;
}
@media (max-width: 768px) {
  footer .footer-content {
    margin: 1rem auto;
  }
}
@media (max-width: 1024px) {
  footer .footer-content {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  footer .footer-content {
    align-items: center;
  }
}
footer .footer-content .footer-logo {
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 700;
  padding: 1rem;
  color: #483448;
}
@media (max-width: 768px) {
  footer .footer-content .footer-logo {
    text-align: center;
  }
}
footer .footer-content .footer-links {
  display: flex;
  gap: 7.5rem;
  padding: 1rem;
}
@media (max-width: 768px) {
  footer .footer-content .footer-links {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
}
footer .footer-content .footer-links .infos-contact,
footer .footer-content .footer-links .quick-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}
@media (max-width: 768px) {
  footer .footer-content .footer-links .infos-contact,
  footer .footer-content .footer-links .quick-links {
    align-items: center;
  }
}
footer .footer-content .footer-links .infos-contact a,
footer .footer-content .footer-links .quick-links a {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 1rem;
  text-decoration: none;
  color: #260126;
}
footer .footer-content .footer-links .infos-contact a .footer-icon,
footer .footer-content .footer-links .quick-links a .footer-icon {
  font-size: 1.3rem;
}
footer .copyright {
  display: flex;
  flex-wrap: wrap;
  background-color: #260126;
  color: #dfdfdf;
  width: 100%;
  height: 4rem;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}
footer .copyright span {
  width: 85%;
  text-align: center;
}
footer .copyright a {
  color: #dfdfdf;
}

.hero {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
}
.hero .cover-container {
  position: relative;
  width: 100%;
}
.hero .cover-container img {
  width: 100%;
  max-height: 18rem;
  object-fit: cover;
}
@media (max-width: 768px) {
  .hero .cover-container img {
    height: 25vh;
  }
}
.hero .cover-container .page-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 100%;
  font-size: 3rem;
  font-weight: 700;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px rgb(38, 1, 38);
}
@media (max-width: 768px) {
  .hero .cover-container .page-title {
    font-size: 2.25rem;
    top: 40%;
  }
}
@media (max-width: 768px) {
  .hero .cover-container {
    height: 25vh;
  }
}
.hero .section-btns-container {
  display: flex;
  gap: 10rem;
  justify-content: center;
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .hero .section-btns-container {
    gap: 3rem;
  }
}
.hero .section-btns-container .section-btn {
  position: relative;
  font-size: 1.8rem;
  font-weight: 600;
  color: #483448;
  background-color: unset;
  border: none;
  padding: 0;
  cursor: pointer;
  padding-bottom: 5px;
}
@media (max-width: 768px) {
  .hero .section-btns-container .section-btn {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .hero .section-btns-container .section-btn {
    font-size: 1.4rem;
  }
}
.hero .section-btns-container .section-btn.selected::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.25rem;
  background-color: #DEB72A;
}
.hero .section-btns-container .section-btn.agenda {
  cursor: unset;
}

#albuns {
  max-width: 1240px;
  width: 90vw;
  padding: 0 1rem;
}
#albuns .albuns-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-around;
}
#albuns .albuns-container a {
  text-decoration: none;
}
#albuns .albuns-container a .album-cover {
  border-radius: 0.3rem;
}
#albuns .albuns-container a .album-title {
  color: #222222;
  font-size: 1.1rem;
  margin: 0;
}

.streaming-container {
  max-width: 1240px;
  width: 90vw;
  width: 69.75rem;
  padding: 0 1rem;
  height: 13.688rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media (max-width: 1024px) {
  .streaming-container {
    height: 18.625rem;
    width: 50rem;
  }
}
@media (max-width: 768px) {
  .streaming-container {
    height: 18.375rem;
    width: 30rem;
  }
}
@media (max-width: 480px) {
  .streaming-container {
    height: 23.875rem;
    width: 19rem;
  }
}
.streaming-container .streaming-links {
  display: flex;
  gap: 3rem;
  justify-content: center;
  width: 90%;
  margin: 2.5rem 0;
}
@media (max-width: 1024px) {
  .streaming-container .streaming-links {
    flex-wrap: wrap;
    gap: 2.5rem;
  }
}
@media (max-width: 768px) {
  .streaming-container .streaming-links {
    gap: 2.1rem;
  }
}
.streaming-container .streaming-links img {
  height: 2rem;
}
@media (max-width: 768px) {
  .streaming-container .streaming-links img {
    height: 1.85rem;
  }
}
@media (max-width: 480px) {
  .streaming-container .streaming-links img {
    height: 1.75rem;
  }
}

#accordion-item {
  max-width: 1240px;
  width: 90vw;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  border-radius: 0.5rem;
}
#accordion-item.Mui-expanded {
  background-color: #f8f8f8;
}
#accordion-item .accordion-btn {
  color: #222222;
}
#accordion-item .music-title {
  font-family: "Poppins";
  color: #222222;
  text-transform: uppercase;
}
#accordion-item .accordion-item-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}
#accordion-item .accordion-item-content .description {
  margin-bottom: 0.5rem;
  font-family: "Poppins";
  font-size: 0.9rem;
}
#accordion-item .accordion-item-content .button-wrapper {
  display: flex;
  justify-content: flex-end;
}
#accordion-item .accordion-item-content .button-wrapper .cifra-btn {
  text-transform: initial;
  color: #dfdfdf;
  background-color: #260126;
}

.bio-container {
  max-width: 1240px;
  width: 90vw;
  padding: 0 1rem;
  width: 52rem;
  font-style: italic;
  text-align: center;
}
@media (max-width: 1024px) {
  .bio-container {
    width: 39rem;
  }
}
@media (max-width: 768px) {
  .bio-container {
    width: 27rem;
  }
}
@media (max-width: 480px) {
  .bio-container {
    width: 17rem;
  }
}

.hero-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}
.hero-home .home-cover-container {
  position: relative;
  width: 100%;
}
.hero-home .home-cover-container img {
  width: 100vw;
  max-height: 80vh;
  object-fit: cover;
}
.hero-home .home-cover-container .page-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 100%;
  font-size: 3rem;
  font-weight: 700;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px rgb(38, 1, 38);
}
@media (max-width: 768px) {
  .hero-home .home-cover-container .page-title {
    font-size: 2.25rem;
    top: 40%;
  }
}

#videos {
  max-width: 1240px;
  width: 90vw;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
}
#videos .video-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
#videos .video-container:not(:last-child) {
  margin-bottom: 2rem;
}
#videos .video-container:last-child {
  margin-bottom: 0;
}
#videos .video-container .iframe-wrapper {
  position: relative;
  width: 100%;
  padding-top: 54%;
}
#videos .video-container .iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
}

#photos {
  max-width: 1240px;
  width: 90vw;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
}
#photos .photo-gallery {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: repeat(9, 5vw);
  grid-gap: 0.5rem;
}
@media (max-width: 768px) {
  #photos .photo-gallery {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 5vw);
    grid-gap: 0.2rem;
  }
}
#photos .photo-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 0.3rem;
}
#photos .photo-gallery .photo-1 {
  grid-column-start: 5;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 6;
}
@media (max-width: 768px) {
  #photos .photo-gallery .photo-1 {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 6;
  }
}
#photos .photo-gallery .photo-2 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 10;
  object-position: center;
}
@media (max-width: 768px) {
  #photos .photo-gallery .photo-2 {
    grid-column-start: 6;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 6;
    object-position: top;
  }
}
#photos .photo-gallery .photo-3 {
  grid-column-start: 8;
  grid-column-end: 10;
  grid-row-start: 6;
  grid-row-end: 10;
  object-position: center;
}
@media (max-width: 768px) {
  #photos .photo-gallery .photo-3 {
    grid-column-start: 6;
    grid-column-end: 11;
    grid-row-start: 6;
    grid-row-end: 11;
  }
}
#photos .photo-gallery .photo-4 {
  grid-column-start: 5;
  grid-column-end: 8;
  grid-row-start: 6;
  grid-row-end: 10;
  object-position: right;
}
@media (max-width: 768px) {
  #photos .photo-gallery .photo-4 {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 6;
    grid-row-end: 11;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal-backdrop .modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}
.modal-backdrop .modal-content .modal-photo {
  width: 100%;
  height: auto;
  max-height: 90vh;
}
.modal-backdrop .modal-content .modal-close,
.modal-backdrop .modal-content .modal-prev,
.modal-backdrop .modal-content .modal-next {
  position: absolute;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}
.modal-backdrop .modal-content .modal-close {
  top: 10px;
  right: 10px;
}
.modal-backdrop .modal-content .modal-prev {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.modal-backdrop .modal-content .modal-next {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.events-container {
  max-width: 1240px;
  width: 90vw;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
}
.events-container .event {
  display: flex;
  gap: 3rem;
}
@media (max-width: 480px) {
  .events-container .event {
    gap: 2rem;
  }
}
.events-container .event:last-of-type {
  margin-bottom: 2.5rem;
}
.events-container .event .event-date {
  border: 0.115rem solid #260126;
  border-radius: 0.3rem;
  min-width: 6.5rem;
  height: 6.5rem;
}
@media (max-width: 768px) {
  .events-container .event .event-date {
    min-width: 5rem;
    height: 5rem;
  }
}
.events-container .event .event-date .month {
  display: flex;
  justify-content: center;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  font-weight: 600;
  width: 100%;
  background-color: #260126;
  color: #dfdfdf;
}
@media (max-width: 768px) {
  .events-container .event .event-date .month {
    font-size: 0.8rem;
  }
}
.events-container .event .event-date .day {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 2.6rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .events-container .event .event-date .day {
    font-size: 2rem;
  }
}
.events-container .event .event-info {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}
@media (max-width: 768px) {
  .events-container .event .event-info p,
  .events-container .event .event-info a {
    font-size: 0.9rem;
  }
}
.events-container .event .event-info .event-title {
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #222222;
}
@media (max-width: 768px) {
  .events-container .event .event-info .event-title {
    font-size: 1rem;
  }
}
.events-container .event .event-info .hour {
  color: #222222;
  font-weight: 600;
}
.events-container hr {
  margin: 3rem 0;
  width: 100%;
}
.events-container hr:last-of-type {
  display: none;
}
.events-container .comin-soon-msg {
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 300;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.form-container .return-btn {
  font-size: 2rem;
  border: none;
  background-color: unset;
  text-decoration: none;
  color: black;
}
.form-container .return-btn .return-icon {
  padding: 2rem;
}
.form-container input {
  width: 200px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
}
.form-container button {
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  width: 150px;
  cursor: pointer;
}
.form-container .login-btn {
  background-color: green;
}
.form-container .back-btn {
  background-color: gray;
}

#admin-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  background: rgb(63, 94, 251);
  background: linear-gradient(137deg, rgb(63, 94, 251) 0%, rgb(252, 70, 107) 100%);
  color: white;
}

.admin-header {
  max-width: 1240px;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.admin-header .admin-title {
  font-size: 3rem;
}
.admin-header .logout-btn {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  position: absolute;
  top: 2rem;
  background-color: unset;
  border: none;
  font-size: 0.9rem;
  color: white;
  cursor: pointer;
}
.admin-header .logout-btn:hover {
  text-decoration: underline;
}

.admin-msg {
  max-width: 1240px;
  width: 90vw;
  font-style: italic;
}
.admin-msg p {
  color: white;
}
.admin-msg span {
  font-weight: 600;
}

#admin-agenda {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#admin-agenda .admin-agenda {
  max-width: 1240px;
  width: 90vw;
  display: flex;
  flex-direction: column;
}
#admin-agenda .admin-agenda .form-agenda-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
}
#admin-agenda .admin-agenda .form-agenda-container label {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  gap: 0.5rem;
  color: #260126;
}
#admin-agenda .admin-agenda .form-agenda-container input,
#admin-agenda .admin-agenda .form-agenda-container textarea {
  padding: 0.4rem;
}
#admin-agenda .admin-agenda .form-agenda-container #event-date-time {
  width: 10rem;
}
#admin-agenda .admin-agenda .form-agenda-container .add-event-btn {
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #28a745;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
}
#admin-agenda .admin-agenda .form-agenda-container .add-event-btn:disabled {
  background-color: #260126;
  cursor: unset;
}
#admin-agenda .admin-agenda .events-list {
  display: flex;
  flex-direction: column;
}
#admin-agenda .admin-agenda .events-list .list-description {
  font-size: 0.9rem;
}
#admin-agenda .admin-agenda .events-list .list-description span {
  font-weight: 600;
  color: red;
}
#admin-agenda .admin-agenda .events-list ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
}
#admin-agenda .admin-agenda .events-list ul li {
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  background-color: #f8f8f8;
}
@media (max-width: 480px) {
  #admin-agenda .admin-agenda .events-list ul li {
    flex-direction: column;
  }
}
#admin-agenda .admin-agenda .events-list ul li .base-event-name {
  display: flex;
  gap: 1rem;
  color: #222222;
  font-weight: 600;
}
#admin-agenda .admin-agenda .events-list ul li .base-event-name span {
  font-weight: 300;
}
@media (max-width: 480px) {
  #admin-agenda .admin-agenda .events-list ul li .base-event-name {
    justify-content: space-between;
  }
}
#admin-agenda .admin-agenda .events-list ul li button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.2rem;
  margin: 0 0.5rem 0 1rem;
  background-color: pink;
  cursor: pointer;
}
#admin-agenda .admin-agenda .events-list ul li button .trash-icon {
  color: red;
  padding: 0.3rem;
}
@media (max-width: 480px) {
  #admin-agenda .admin-agenda .events-list ul li button .trash-icon {
    margin: 0.5rem;
  }
}
@media (max-width: 480px) {
  #admin-agenda .admin-agenda .events-list ul li button {
    width: 40px;
    align-self: flex-end;
    margin: 0.5rem 0 0 1rem;
  }
}

html,
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

main {
  margin: 0 auto;
  padding-bottom: 3.5rem;
  color: #222222;
}

.content {
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
}
@media (max-width: 480px) {
  .content {
    gap: 2rem;
  }
}

.content.loaded {
  opacity: 1;
}

