@use "../variables.scss";
@use "../mixins.scss";

.hero-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  .home-cover-container {
    position: relative;
    width: 100%;
    img {
      width: 100vw;
      max-height: 80vh;
      object-fit: cover;
    }
    .page-title {
      @include mixins.page-title();
    }
  }
}
