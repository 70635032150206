@use "../mixins.scss";
@use "../variables.scss";

#albuns {
  @include mixins.max-width();
  padding: 0 1rem;
  .albuns-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-around;
    a {
      text-decoration: none;
      .album-cover {
        border-radius: 0.3rem;
      }
      .album-title {
        color: variables.$black-text();
        font-size: 1.1rem;
        margin: 0;
      }
    }
  }
}
