.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    .modal-photo {
      width: 100%;
      height: auto;
      max-height: 90vh;
    }
    .modal-close,
    .modal-prev,
    .modal-next {
      position: absolute;
      background: none;
      border: none;
      color: white;
      font-size: 2rem;
      cursor: pointer;
    }
    .modal-close {
      top: 10px;
      right: 10px;
    }
    .modal-prev {
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
    .modal-next {
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
}
