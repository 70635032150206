@use "../mixins.scss";

.streaming-container {
  @include mixins.max-width();
  width: 69.75rem;
  padding: 0 1rem;
  height: 13.688rem; 
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (max-width: 1024px) {
    height: 18.625rem;
    width: 50rem;
  }
  @media (max-width: 768px) {
    height: 18.375rem;
    width: 30rem;
  }
  @media (max-width: 480px) {
      height: 23.875rem;
      width: 19rem;
    }
  .streaming-links {
    display: flex;
    gap: 3rem;
    justify-content: center;
    width: 90%;
    margin: 2.5rem 0;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
      gap: 2.5rem;
    }
    @media (max-width: 768px) {
      gap: 2.1rem;
    }
    img {
      height: 2rem;
      @media (max-width: 768px) {
        height: 1.85rem;
      }
      @media (max-width: 480px) {
        height: 1.75rem;
      }
    }
  }
}
