@use "../variables.scss";
@use "../mixins.scss";

.hero {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  .cover-container {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      max-height: 18rem;
      object-fit: cover;
      @media (max-width: 768px) {
        height: 25vh;
      }
    }
    .page-title {
      @include mixins.page-title();
    }
    @media (max-width: 768px) {
      height: 25vh;
    }
  }
  .section-btns-container {
    display: flex;
    gap: 10rem;
    justify-content: center;
    margin-top: 1rem;
    @media (max-width: 768px) {
      gap: 3rem;
    }
    .section-btn {
      position: relative;
      font-size: 1.8rem;
      font-weight: 600;
      color: variables.$violet-title;
      background-color: unset;
      border: none;
      padding: 0;
      cursor: pointer;
      padding-bottom: 5px;
      @media (max-width: 768px) {
        font-size: 1.6rem;
      }
      @media (max-width: 480px) {
        font-size: 1.4rem;
      }
      &.selected {
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.25rem;
          background-color: variables.$underline-btn;
        }
      }
      &.agenda {
        cursor: unset;
      }
    }
  }
}
