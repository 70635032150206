@use "../mixins.scss";
@use "../variables.scss";

.events-container {
  @include mixins.max-width();
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  .event {
    display: flex;
    gap: 3rem;
    @media (max-width: 480px) {
      gap: 2rem;
    }
    &:last-of-type {
      margin-bottom: 2.5rem;
    }
    .event-date {
      border: 0.115rem solid variables.$black-background;
      border-radius: 0.3rem;
      min-width: 6.5rem;
      height: 6.5rem;
      @media (max-width: 768px) {
        min-width: 5rem;
        height: 5rem;
      }
      .month {
        display: flex;
        justify-content: center;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        font-weight: 600;
        width: 100%;
        background-color: variables.$black-background;
        color: variables.$light-text;
        @media (max-width: 768px) {
          font-size: 0.8rem;
        }
      }
      .day {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 2.6rem;
        font-weight: 700;
        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }
    .event-info {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      p,
      a {
        @media (max-width: 768px) {
          font-size: 0.9rem;
        }
      }
      .event-title {
        font-size: 1.2rem;
        font-style: italic;
        font-weight: 600;
        margin-bottom: 0.5rem;
        color: variables.$black-text;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }
      .hour {
        color: variables.$black-text;
        font-weight: 600;
      }
    }
  }
  hr {
    margin: 3rem 0;
    width: 100%;
    &:last-of-type {
      display: none;
    }
  }
  .comin-soon-msg {
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 300;
  }
}
