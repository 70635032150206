@use "../mixins.scss";

#videos {
  @include mixins.max-width();
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  .video-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .iframe-wrapper {
      position: relative;
      width: 100%;
      padding-top: 54%; // 16:9 aspect ratio
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: .3rem;
      }
    }
  }
}
