@use "../mixins.scss";
@use "../variables.scss";

#accordion-item {
  @include mixins.max-width();
  display: flex;
  flex-direction: column;
  box-shadow: none;
  border-radius: 0.5rem;
  &.Mui-expanded {
    background-color: variables.$gray-background;
  }
  .accordion-btn {
    color: variables.$black-text;
  }
  .music-title {
    font-family: "Poppins";
    color: variables.$black-text;
    text-transform: uppercase;
  }
  .accordion-item-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    .description {
      margin-bottom: 0.5rem;
      font-family: "Poppins";
      font-size: 0.9rem;
    }
    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      .cifra-btn {
        text-transform: initial;
        color: variables.$light-text;
        background-color: variables.$black-background;
      }
    }
  }
}
