@use "../mixins.scss";

.bio-container {
  @include mixins.max-width();
  padding: 0 1rem;
  width: 52rem;
  font-style: italic;
  text-align: center;
  @media (max-width: 1024px) {
    width: 39rem;
  }
  @media (max-width: 768px) {
    width: 27rem;
  }
  @media (max-width: 480px) {
    width: 17rem;
  }
}
