@use "../variables.scss";

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: variables.$gray-background;
  .footer-content {
    display: flex;
    justify-content: space-between;
    width: 90vw;
    max-width: 1240px;
    margin: 2.5rem auto;
    @media (max-width: 768px) {
      margin: 1rem auto;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    @media (max-width: 768px) {
      align-items: center;
    }
    .footer-logo {
      font-size: 3rem;
      line-height: 3.5rem;
      font-weight: 700;
      padding: 1rem;
      color: variables.$violet-title;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .footer-links {
      display: flex;
      gap: 7.5rem;
      padding: 1rem;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
      }
      .infos-contact,
      .quick-links {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
        @media (max-width: 768px) {
          align-items: center;
        }
        a {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          font-size: 1rem;
          text-decoration: none;
          color: variables.$gray-text;
          .footer-icon {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
  .copyright {
    display: flex;
    flex-wrap: wrap;
    background-color: variables.$black-background;
    color: variables.$light-text;
    width: 100%;
    height: 4rem;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    span {
      width: 85%;
      text-align: center;
    }
    a {
      color: variables.$light-text;
    }
  }
}
