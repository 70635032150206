.form-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  .return-btn {
    font-size: 2rem;
    border: none;
    background-color: unset;
    text-decoration: none;
    color: black;
    .return-icon {
      padding: 2rem;
    }
  }
  input {
    width: 200px;
    padding: .5rem;
    border-radius: .5rem;
    border: none;
  }
  button {
    color: white;
    padding: .5rem;
    border-radius: .5rem;
    border: none;
    width: 150px;
    cursor: pointer;;
  }
  .login-btn {
    background-color: green;
  }
  .back-btn {
    background-color: gray;
  }
}
