@mixin max-width {
  max-width: 1240px;
  width: 90vw;
}

@mixin page-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 100%;
  font-size: 3rem;
  font-weight: 700;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(38, 1, 38, 1);
  @media (max-width: 768px) {
    font-size: 2.25rem;
    top: 40%;
  }
}
